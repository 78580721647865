//
//
//
//
//
//
//

import ThemePicker from "@/components/ThemePicker";
export default {
  name: "App",
  components: {
    ThemePicker
  },
  created() {
    console.log("App");
    this.$store.dispatch("settings/changeSetting", {
      key: "sideTheme",
      value: "theme-dark"
    });
  }
};