function install(Vue, opts = {}) {
  Vue.mixin({
    created() {
      const onShowHook = this.$options["onShow"];
      const onShowLazyHook = this.$options["onShowLazy"];
      const onHideHook = this.$options["onHide"];

      if (onShowHook != null || onHideHook != null || onShowLazyHook != null) {
        this.onShowHook();
        document.addEventListener(
          "visibilitychange",
          this.visibilitychangeCallback,
        );
      }
    },
    destroyed() {
      document.removeEventListener(
        "visibilitychange",
        this.visibilitychangeCallback,
      );
    },
    methods: {
      visibilitychangeCallback() {
        const onShowHook = this.$options["onShow"]?.bind(this);
        const onShowLazyHook = this.$options["onShowLazy"]?.bind(this);
        const onHideHook = this.$options["onHide"]?.bind(this);

        if (document.visibilityState == "visible") {
          console.log("visible");
          if (typeof onShowHook === "function") {
            onShowHook();
            onShowLazyHook();
          }
        }

        if (document.visibilityState == "hidden") {
          console.log("hidden");
          if (typeof onHideHook === "function") {
            onHideHook();
          }
        }
      },
    },
  });
}

export default {
  install,
};
